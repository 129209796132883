
import React from 'react'
import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useDispatch } from 'react-redux'

import { Bars3Icon } from '@heroicons/react/24/outline'
import langIcon from '../assets/images/mkhHeader/lang.png'
import setupIcon from '../assets/images/mkhHeader/setup.png'

import logoIcon from '../assets/images/header/logoTitle.png'
import LangModal from './LangModal'
import Setup from './Setup/Setup'
import { show as setupShow } from '../store/modules/setupStore'
import { show as langModalShow } from '../store/modules/langModalStore'
function Header () {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => { }, [])
  useEffect(() => {
    // if (!localStorage.getItem('language')) {
    //   localStorage.setItem('language', 'zh_TW')
    // } else {
    //   if (localStorage.getItem('language') == 'zh_CN') {
    //     i18n.changeLanguage('zh_CN')
    //   } else if (localStorage.getItem('language') == 'en_US') {
    //     i18n.changeLanguage('en_US')
    //   } else if (localStorage.getItem('language') == 'zh_TW') {
    //     i18n.changeLanguage('zh_TW')
    //   } else if (localStorage.getItem('language') == 'ja') {
    //     i18n.changeLanguage('ja')
    //   } else if (localStorage.getItem('language') == 'ko') {
    //     i18n.changeLanguage('ko')
    //   } else if (localStorage.getItem('language') == 'es') {
    //     i18n.changeLanguage('es')
    //   } else if (localStorage.getItem('language') == 'pt') {
    //     i18n.changeLanguage('pt')
    //   } else if (localStorage.getItem('language') == 'de') {
    //     i18n.changeLanguage('de')
    //   } else if (localStorage.getItem('language') == 'fr') {
    //     i18n.changeLanguage('fr')
    //   } else if (localStorage.getItem('language') == 'th') {
    //     i18n.changeLanguage('th')
    //   }
    // }
  }, [])

  return (
    <div className=" relative flex justify-between w-screen h-[43px] items-center  z-2 ">
      {/*logo */}
      <div className="flex-auto ml-4 flex items-center">
        <img src={logoIcon} className="w-[30px] h-[30px]" />
        <div className=' font-bold text-[20px] ml-2'>STV</div>
        <LangModal></LangModal>
      </div>
      <div
        className="  flex justify-end"
        onClick={() => {
          dispatch(langModalShow())
        }}>
        <img src={langIcon} alt="" className="w-7 h-7  mr-3" />
      </div>
      <div className=" mr-4 flex justify-end">
        <Setup />
        <div
          className="w-9 h-9 flex justify-center items-center "
          onClick={() => {
            dispatch(setupShow())
          }}>
          <img src={setupIcon} alt="" className="w-7 h-7  mr-3" />
          {/* <Bars3Icon className="text-[#000] w-9 h-9"></Bars3Icon> */}
        </div>
      </div>
    </div>
  )
}

export default Header
