/*eslint-disable*/
import axios from "axios";
import { getToken, clearToken } from '../utils/token'
import { layout } from '../utils/layout'
import { store } from "../index";
// import {addTodo} from "../redux/actions";

function normalJs (props) {
  // console.log('普通js文件');
  // console.log('store', store);
  // console.log('store.getState', store.getState());
  return store.getState().signStore.signInfo
  // store.dispatch(addTodo('通js文件aaaa'))
}
export { normalJs }
axios.defaults.retry = 0;
axios.defaults.retryDelay = 1000;

const axiosOption = {

  // baseURL: "https://adnbo.web3game.finance/api",
  baseURL: "https://backstage.stv-ai.com/api",

  timeout: 5000
}

const instance = axios.create(axiosOption);

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // let token = localStorage.getItem("token")
  // if(token){
  //   config.headers = {
  //     "token":token
  //   }
  // }
  const normal = normalJs()
  // console.log('normal', normal);
  const token = getToken()
  if (token) {
    config.headers.token = `${token}`
  } else if (normal.sign) {
    config.headers.token = `${normal.sign}`
  }
  if (localStorage.getItem('lang')) {
    config.headers['accept-language'] = localStorage.getItem('lang')
  }
  else {
    config.headers['accept-language'] = 'zh_TW'
  }
  return config;

}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器

// instance.interceptors.response.use(undefined, function axiosRetryInterceptor (err) {
//   var config = err.config;
//   if (!config || !config.retry) return Promise.reject(err);
//   config.__retryCount = config.__retryCount || 0;

//   if (config.__retryCount >= config.retry) {
//     return Promise.reject(err);
//   }

//   config.__retryCount += 1;
//   var backoff = new Promise(function (resolve) {
//     setTimeout(function () {
//       resolve();
//     }, config.retryDelay || 1);
//   });

//   return backoff.then(function () {
//     return axios(config);
//   });

// });
// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // response返回上方请求拦截器的config配置信息，data后台接口返回的数据，headers服务器响应头等信息

  // 由于微信小程序的 toast 和 loading 相关接口可以相互混用，所以需要取消混用提示，也是关联上方的“加载中”优化
  // if (ajaxTimes > 0 && --ajaxTimes === 0) {
  //   wx.hideLoading({noConflict:true})  // // 取消混用提示
  // }

  // 这里返回的response.data就是后台接口返回的数据
  const res = response
  // 如果返回的code不是200或201,则判断为错误

  if (res.data.code && res.data.code !== 200 && res.data.code !== 201) {
    // 分别对这些错误进行对应的处理
    if (res.data.code === 10002) {
      clearToken()
      layout()
      // window.location.href = '/'
      return   // 这里调用获取token的方法，后面会进行配置
    }
    if (res.data.code === 10004 || res.data.code === 10005) {
      // 1004状态码表示token过期，需要重新获取token，并在获取token后，重新请求接口，清除token

      // console.log(res)
      clearToken()
      layout()
      // window.location.href = '/' 
      return   // 这里调用获取token的方法，后面会进行配置

    }
    // else if(res.code === 9001){
    //     // 返回9001状态码处理，通过wx.login获取code
    //     // if (res.message.indexOf('code been used') !== -1) {
    //     //   getApp().globalData.userInfo._getLoginCode()
    //     // } else if (res.message.indexOf('invalid code') !== -1) {
    //     //   console.log('invalid code')
    //     // }
    //     return Promise.reject(res)

    // } else if (res.code === 400 || res.code === 500){
    //     // 返回400或500状态码，表示服务器错误或者接口请求异常
    //     // wx.showToast({title: `${res.message}`,icon: 'none'})
    //     return Promise.reject(res)

    // } else if(res.code == 30011){  // 账户封禁
    //     // 返回30011状态码，表示该账号已封禁，如果想对封禁的账户做一些操作可以将返回的信息携带并跳转到封禁页，封禁页展示封禁的原有和信息，用户可以在封禁页做封禁申诉等操作
    //     // const info = res.message;
    //     // wx.reLaunch({
    //     //   url: `/pages/account/index?info=${info}`,
    //     // })
    // }
    return res
    // return Promise.reject(res) // 其他的错误状态码就直接reject
  } else {
    return res
  }
}, function (error) {
  // 处理响应错误
  //  if (ajaxTimes > 0 && --ajaxTimes === 0) {
  //       wx.hideLoading({noConflict:true})
  //  }
  return Promise.reject(error);
});



export default instance;

